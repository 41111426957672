import { render, staticRenderFns } from "./CreditsReturn.vue?vue&type=template&id=5377fc10&"
import script from "./CreditsReturn.vue?vue&type=script&lang=ts&"
export * from "./CreditsReturn.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kenneth/jumppoint/jumppoint-front-end/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5377fc10')) {
      api.createRecord('5377fc10', component.options)
    } else {
      api.reload('5377fc10', component.options)
    }
    module.hot.accept("./CreditsReturn.vue?vue&type=template&id=5377fc10&", function () {
      api.rerender('5377fc10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/iframe/CreditsReturn.vue"
export default component.exports