




import { Component, Vue, Prop } from "vue-property-decorator";
// import IframePrompt from "@/components/prompts/IframePrompt.vue";

@Component
export default class CreditReturn extends Vue {
  async mounted() {
    // emit event from iframe -> main page
    // reference: https://stackoverflow.com/questions/9153445/how-to-communicate-between-iframe-and-the-parent-site
    const event: CustomEvent = new CustomEvent("quit-iframe", { detail: {} });
    window.parent.document.dispatchEvent(event);

  }
}
